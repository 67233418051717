<template>
  <div>
    <h2 class="contact__secondary-heading">
      <span>お問い合わせが送信されました</span>
    </h2>
    <p class="contact__text">
      <span class="em">お問い合わせいただきありがとうございます。</span><br />
      <span class="em">2営業日以内に返信させていただきます。</span><br />
      <br />
      <span class="sup"
        >2営業日以上たっても返信が無い場合は、<br />大変お手数おかけしますが再送いただきますようお願い致します。</span
      ><br />
    </p>
    <div class="btn btn--red contact__btn-toindex">
      <router-link to="/"> トップページへ戻る </router-link>
    </div>
  </div>
</template>
